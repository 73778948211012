import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  style: {
    "display": "flex",
    "justify-content": "space-around",
    "align-items": "center"
  }
};
const _hoisted_2 = {
  style: {
    "margin": "10px"
  }
};
const _hoisted_3 = {
  style: {
    "font-size": "12px",
    "font-weight": "400",
    "color": "rgb(120, 120, 120)"
  }
};
const _hoisted_4 = {
  class: "custom-title"
};
import { ref, computed, toRefs, watch, onMounted } from 'vue';
import http from "@/utils/request";
import { showLoadingToast } from 'vant';
import { showConfirmDialog } from 'vant';
//光盘进度条

export default {
  __name: 'TeacherClass',
  props: {
    time: String,
    classId: Number
  },
  setup(__props) {
    const props = __props;
    onMounted(() => {
      getclass();
    });
    const gradientColor = {
      '0%': '#3fecff',
      '100%': '#6149f6'
    };
    const {
      time,
      classId
    } = toRefs(props);
    watch(time, () => {
      getclass();
    });
    const currentRate1 = ref(0);
    const text1 = computed(() => currentRate1.value.toFixed(0) + '%' + '光盘率');
    const currentRate2 = ref(0);
    const text2 = computed(() => guangpan.value + '人' + '已光盘');
    const data = ref([]);
    const headcount = ref(0); //总人数
    const guangpan = ref(0); //已光盘
    const scale = ref(0); //光盘率
    const getclass = () => {
      const toasts = showLoadingToast({
        message: '加载中...',
        forbidClick: true,
        duration: 0
      });
      http({
        url: '/api/v1/identify-record/class-user-identify-statistical-detail',
        params: {
          classId: classId.value,
          date: time.value,
          role: 'student'
        }
      }).then(res => {
        data.value = res.data;
        headcount.value = data.value.length;
        let num = 0;
        data.value.map(item => {
          if (item.haveCdForLunch) num++;
        });
        guangpan.value = num;
        scale.value = (guangpan.value / headcount.value * 100).toFixed(2);
        data.value.sort((a, b) => {
          return b.haveCdForLunch ? 1 : -1;
        });
        toasts.close();
      }).catch(() => {
        toasts.close();
        showConfirmDialog({
          title: '获取失败!',
          message: '是否重新获取班级成员光盘情况'
        }).then(() => {
          getclass();
        }).catch(() => {
          data.value = [];
          headcount.value = 0;
          guangpan.value = 0;
          scale.value;
          // on cancel
        });
      });
    };

    return (_ctx, _cache) => {
      const _component_van_circle = _resolveComponent("van-circle");
      const _component_van_cell = _resolveComponent("van-cell");
      return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_van_circle, {
        "current-rate": currentRate1.value,
        "onUpdate:currentRate": _cache[0] || (_cache[0] = $event => currentRate1.value = $event),
        rate: scale.value,
        "stroke-width": 60,
        color: gradientColor,
        speed: 100,
        text: text1.value
      }, null, 8, ["current-rate", "rate", "text"]), _createVNode(_component_van_circle, {
        "current-rate": currentRate2.value,
        "onUpdate:currentRate": _cache[1] || (_cache[1] = $event => currentRate2.value = $event),
        rate: scale.value,
        "stroke-width": 60,
        speed: 100,
        "layer-color": "#e43223",
        text: text2.value
      }, null, 8, ["current-rate", "rate", "text"])]), _createElementVNode("h4", _hoisted_2, [_createTextVNode("学生光盘情况"), _createElementVNode("span", _hoisted_3, "(总" + _toDisplayString(headcount.value) + "人)", 1)]), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data.value, item => {
        return _openBlock(), _createBlock(_component_van_cell, {
          key: item.userid
        }, {
          title: _withCtx(() => [_createElementVNode("span", _hoisted_4, _toDisplayString(item.name), 1)]),
          value: _withCtx(() => [_createElementVNode("span", {
            class: _normalizeClass(item.haveCdForLunch ? 'iscolor' : 'notcolor')
          }, _toDisplayString(item.haveCdForLunch ? '已光盘' : '未光盘'), 3)]),
          _: 2
        }, 1024);
      }), 128))]);
    };
  }
};