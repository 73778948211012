import axios from "axios";
const http = axios.create({
  baseURL: 'https://api.wx.st.lishuidingtalk.com',
  timeout:50000,
});
 
let loadingInstance;
 
// 拦截器的添加
http.interceptors.request.use(
  (config) => {
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);
 
//响应拦截器
http.interceptors.response.use(
  (res) => {
    return res.data;
  },
  (err) => {
    return Promise.reject(err);
  }
);
export default http;