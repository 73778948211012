import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "custom-title"
};
import { ref, toRefs, watch, onMounted } from 'vue';
import http from "@/utils/request";
import { showLoadingToast } from 'vant';
import { showConfirmDialog } from 'vant';
export default {
  __name: 'SelfChildren',
  props: {
    time: String,
    userId: String
  },
  setup(__props) {
    const props = __props;
    onMounted(() => {
      getclass();
    });
    const {
      time,
      userId
    } = toRefs(props);
    watch(time, () => {
      getclass();
    });
    const data = ref();
    const getclass = () => {
      const toasts = showLoadingToast({
        message: '加载中...',
        forbidClick: true,
        duration: 0
      });
      http({
        url: '/api/v1/identify-record/guardian-identification-statistics',
        params: {
          userid: userId.value,
          date: time.value
        }
      }).then(res => {
        data.value = res.data;
        toasts.close();
      }).catch(() => {
        toasts.close();
        showConfirmDialog({
          title: '获取失败!',
          message: '是否重新获取班级成员光盘情况'
        }).then(() => {
          getclass();
        }).catch(() => {
          data.value = [];
        });
      });
    };
    return (_ctx, _cache) => {
      const _component_van_cell = _resolveComponent("van-cell");
      return _openBlock(), _createElementBlock("div", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data.value, item => {
        return _openBlock(), _createBlock(_component_van_cell, {
          key: item.userid
        }, {
          title: _withCtx(() => [_createElementVNode("span", _hoisted_1, _toDisplayString(item.name), 1)]),
          value: _withCtx(() => [_createElementVNode("span", {
            class: _normalizeClass(item.haveCdForLunch ? 'iscolor' : 'notcolor')
          }, _toDisplayString(item.haveCdForLunch ? '已光盘' : '未光盘'), 3)]),
          _: 2
        }, 1024);
      }), 128))]);
    };
  }
};