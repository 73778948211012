import { createApp } from 'vue'
import App from './App.vue'
import { Collapse, CollapseItem } from 'vant';
import 'vant/lib/index.css';
import { initDingH5RemoteDebug } from "dingtalk-h5-remote-debug";
initDingH5RemoteDebug();
const app = createApp(App);
app.use(Collapse);
app.use(CollapseItem);
app.mount('#app')
